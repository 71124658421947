import { GLOBAL_CONFIG } from 'src/app/configs/global.config';

export const environment = {
  production: false,
  api_url: 'https://gw-dev.cube.rina.org/diassteeldecarbon-func/dev',
  admin_tool_url: 'https://gw-dev.cube.rina.org/admin-tool',
  ...GLOBAL_CONFIG,
  powerbi: {
    groupId: '',
    reportId: '',
  },
  b2cPolicies: {
    clientId: 'c488675b-76ef-4156-9445-168f6b7287c5',
    tenantId: 'c7788286-49f4-40db-bf26-fc1c677df787',
    redirectUri: 'https://co2neutral-dev.cube.rina.org',
    redirectCourtesyPageUri: 'https://www.rina.org/',
    postLogoutRedirectUri: 'https://www.rina.org/',
    tenant : 'rinagroupb2cdevl',
    names: {
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
      resetPassword: 'B2C_1A_PASSWORDRESET',
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      },
      resetPassword: {
        authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PASSWORDRESET',
      },
      editProfile: {
        authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PROFILEEDIT',
      },
    },
    domain: 'https://test-login.rina.org/',
    subdomain: 'rinagroupb2cdevl.onmicrosoft.com',
    authorityDomain: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com',
  },
  localStorageKeyPrefix: 'cube-mt-dev',
};
