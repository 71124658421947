/* eslint-disable @ngrx/no-typed-global-store */
import { Directive } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { SystemActions } from '../store/actions/system.actions';
import { LanguageOption } from '../models/core/language-option.type';
import { selectCurrentLang } from '../store/selectors/system.selectors';
import { AppState } from '../store/states/app.state';
import { selectAdditionalLanguages } from '../store/selectors/auth.selectors';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[cubeTranslate]', standalone: true })
export class CubeTranslateDirective {
  constructor(
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>
  ) {
    const browserLang = this.translateService.getBrowserLang();
    const currentLang: LanguageOption = browserLang === 'it' ? 'it' : 'en';
    this.store.dispatch(SystemActions.setCurrentLang({ currentLang }));

    this.store
      .select(selectCurrentLang)
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (language: LanguageOption) => this.translateService.use(language),
      });

    this.store
      .select(selectAdditionalLanguages)
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (additional_languages: Array<LanguageOption>) => {
          const browserLang = this.translateService.getBrowserLang();
          if (additional_languages.includes(browserLang as LanguageOption)) {
            this.store.dispatch(SystemActions.setCurrentLang({ currentLang: browserLang as LanguageOption }));
          } else this.store.dispatch(SystemActions.setCurrentLang({ currentLang: 'en' }));
        },
      });
  }
}
