import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

import { AppState } from './store/states/app.state';
import { Theme } from './models/core/theme.type';
import { selectTheme } from './store/selectors/system.selectors';
import { CubeLayoutModeHandlerDirective } from './directives/layout-mode-handler.directive';
import { NotificationHandlerDirective } from './directives/notification-handler.directive';
import { CubeTranslateDirective } from './directives/translate.directive';
import { TokenService } from './providers/services/token.service';
import { AuthActions } from './store/actions/auth.actions';
import { AuthenticationService } from './providers/services/authentication.service';
import { TokenDetails } from './models/auth/token-details.type';
import { concatLatestFrom } from '@ngrx/effects';
import { selectTokenDetails, selectUserTenants } from './store/selectors/auth.selectors';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'cube-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NotificationHandlerDirective,
    CubeTranslateDirective,
    CubeLayoutModeHandlerDirective,
  ],
})
export class AppComponent {
  private readonly authService: AuthenticationService = inject(AuthenticationService);
  private readonly store: Store<AppState> = inject(Store);
  private readonly tokenService: TokenService = inject(TokenService);
  isDarkTheme: boolean = false;
  themeClass: string = 'light-theme';

  constructor() {
    this.store.dispatch(AuthActions.login());
  
    this.authService.token.pipe(
      filter((token: string | undefined): token is string => !!token)).subscribe(token => {
        const uniqueId = this.tokenService.getUniqueId(token);
        this.store.dispatch(AuthActions.setTokenDetails({tokenDetails: {...jwtDecode(token), uniqueId} as TokenDetails}))})
  
    this.store
      .select<Theme>(selectTheme)
      .pipe(
        // eslint-disable-next-line @ngrx/avoid-mapping-selectors
        map((theme: Theme): boolean => theme === 'dark'),
        takeUntilDestroyed()
      )
      .subscribe({
        next: (isDark: boolean) => {
          this.isDarkTheme = isDark;
          const newThemeClass: string = isDark ? 'dark-theme' : 'light-theme';

          const bodyElement = document.body;
          if (bodyElement) {
            bodyElement.classList.remove(this.themeClass);
            bodyElement.classList.add(newThemeClass);
            this.themeClass = newThemeClass;
          }
        },
      });
      this.store
      .select(selectTokenDetails)
      .pipe(
        filter((tokenDetails: TokenDetails | undefined): tokenDetails is TokenDetails => !!tokenDetails),
        concatLatestFrom(() => this.store.select(selectUserTenants)),
        takeUntilDestroyed()
      )
      .subscribe({
        next: ([tokenDetails, tenants]) => {
          if (!tenants.length) {
            this.store.dispatch(AuthActions.getUserTenants({ uniqueId: tokenDetails.uniqueId }));
          }
        },
      });
  }
}
