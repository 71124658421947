import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../models/response-model.type';

export interface IDiasHttpService {
  get<T>(path: string, params: HttpParams): Observable<ResponseModel<T>>;
  post<T>(path: string, data: T, params: HttpParams): Observable<ResponseModel<T>>;
  put<T>(path: string, data: T, params: HttpParams): Observable<ResponseModel<T>>;
  delete(path: string, params: HttpParams): Observable<ResponseModel<unknown>>;
}

@Injectable()
export abstract class DiasHttpService implements IDiasHttpService {
  constructor(protected readonly http: HttpClient) {}

  get<T>(path: string, params: HttpParams = new HttpParams()): Observable<ResponseModel<T>> {
    return this.http.get<ResponseModel<T>>(`${environment.api_url}/${path}`, { params });
  }

  post<T, K>(path: string, data: T, params: HttpParams = new HttpParams()): Observable<ResponseModel<K>> {
    return this.http.post<ResponseModel<K>>(`${environment.api_url}/${path}`, data, { params });
  }

  put<T, K>(path: string, data: T, params: HttpParams = new HttpParams()): Observable<ResponseModel<K>> {
    return this.http.put<ResponseModel<K>>(`${environment.api_url}/${path}`, data, { params });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete(path: string, params: HttpParams = new HttpParams()): Observable<ResponseModel<unknown>> {
    return this.http.delete<ResponseModel<unknown>>(`${environment.api_url}/${path}`, { params });
  }
}
