import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiasHttpService } from 'src/app/abstractions/dias-http.service';
import { ResponseModel } from 'src/app/models/response-model.type';
import { Client } from '../../models/client.type';
import { ClientConfig } from 'src/app/models/core/client-config.type';

@Injectable()
export class ClientHttpService extends DiasHttpService {
  path = 'ClientRegistryHttpTrigger';

  constructor(http: HttpClient) {
    super(http);
  }

  _get(): Observable<ResponseModel<Client>> {
    return super.get<Client>(this.path);
  }

  _post(client: Client): Observable<ResponseModel<Client>> {
    return super.post<Client, Client>(this.path, client);
  }

  _put(client: Client): Observable<ResponseModel<Client>> {
    return super.put<Client, Client>(this.path, client);
  }

  getClientConfig(): Observable<ResponseModel<ClientConfig>>{
    return super.get<ClientConfig>(`${this.path}/customerconfigs`);
  }
}
